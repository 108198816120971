@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
      @apply bg-indigo-900
    }   
  }

@layer components {
  img {
    @apply mt-0 mb-0 m-0;
  } 
}

@layer utilities {
  img {
    @apply mt-0 mb-0 m-0;
  } 
}

.green {
    background: linear-gradient(112.55deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%), #1D5541;
    background-blend-mode: overlay, normal;
}

.purple {
    background: linear-gradient(112.55deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%), #21235A;
    background-blend-mode: overlay, normal;
}

.cyan {
    background: linear-gradient(112.55deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%), #00555A;
    background-blend-mode: overlay, normal;
}

